import React from "react";
import ContentRight from "../../components/ContentRight";


function Article9() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Marriage Guidance Counselling</h2>

                <p>The dynamics of marriage have changed considerably in the past couple of decades. Marriage as an
                    institution is no longer what it used to be. Couples no longer stick around in a marriage if
                    they feel that the marriage is no longer fulfilling their expectations. At the same times,
                    expectations in a marriage too have changed dramatically over the past couple of decades. </p>

                <h3>Dynamics of Marriage in Earlier Days</h3>

                <p>Previously, men expected women to do the household work and women wanted their men to bring home
                    “the bacon”. Both were satisfied when these expectations were met. However, women were not
                    financially independent so were unable to leave an unhappy marriage. Therefore, even when a
                    marriage was unsuccessful, men and women chose to stay in the marriage due to mere convenience
                    of arrangement. </p>

                <h3>Why Marriage Counselling is Important </h3>

                <p>The earlier dynamics do not hold true today. Life expectancy too has increased significantly and
                    people feel that if one marriage did not work out, they still have a chance of spending the rest
                    of their life with someone else. Therefore, the pressure on marriages has increased to a very
                    large extent. For that reason, marriage counselling has become very important these days. </p>

                <p>Couples tend to experience issues in the early years of their marriage or during the later years
                    due to the ‘empty nest’ circumstances. No matter what stage of marriage you are in, marriage
                    counselling can help you to address your issues and resolve marital conflicts in a productive
                    manner.</p>

                <p>Many people used to think that marriage counselling is for people who are at the brink of
                    divorce. However, truth is that marriage counselling is not only for people who want to give
                    their marriage a last chance but also for couples who wish to strengthen their marriage. </p>

                <p>For marriage counselling to be successful, both the partners need to be willing to commit to
                    marriage counselling sessions and learn from these sessions. </p>

                <h3>How Marriage Guidance Counselling Can Help You </h3>

                <p>Marriage counselling can help people understand their relationship, underlying issues, as well as
                    their behavioural patterns which might be destructive to the marriage. Such sessions can also be
                    attended by couples who are about to get married to discuss their pre-marital issues. </p>

                <h3>Role of a Marriage Counsellor </h3>

                <p>Amidst heated arguments and bitterness, couples are hardly able to concentrate on the real
                    issues. It is common for couples to play the blame game while the actual issue remains
                    unresolved. In such cases, a marriage counsellor can help the couples focus on their
                    problems. </p>

                <p>Even if the couples digress, a counsellor is able to help the couple get back to the problem. A
                    marriage counsellor is able to play the role of a neutral mediator between the couple.
                    Therefore, couples are able to address their issues in a very effective manner. </p>

                <p>Often, couples are so engrossed in their fight that they are unable to come up with a solution. A
                    counsellor is able to provide valuable inputs and help the couples view their problems in a
                    different perspective. Consequently, couples are able to come up with effective solutions to
                    their problems. </p>

                <p>Counselling sessions are confidential. Therefore, you can trust your counsellor and share your
                    issues with utmost confidence. </p>

                <h3>Benefits of Marriage Counselling</h3>

                <p>In the counselling sessions, you will be able to address your marital problems in a productive
                    manner. Therefore, you will most likely end up resolving the issues and be able to understand
                    each other in a much better manner. </p>

                <p>Repeated fights can give rise to a certain amount of acrimony between the couple. It is hard to
                    empathise with the other partner when such feelings rule the mind. However, in counselling
                    sessions, when a neutral party is able to explain how the other partner is feeling, people are
                    able to appreciate the other partner’s viewpoint. </p>

                <p>Very often, the counselling sessions enable the couples to uncover some problems that might seem
                    harmless to them but can actually spell disaster from a long-term perspective. When couples
                    address these problems in advance, they are able to avoid future agony and pain. </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article9;