import React from "react";
import ContentRight from "../../components/ContentRight";


function Article8() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>How to Cope with Infidelity in a Marriage</h2>

                <p>Making a marriage work requires a lot of time, patience, hard work, and perseverance. However,
                    not all couples are able to make a marriage work. The forbidden fruit often comes into picture
                    and can create unforeseen complications in a marriage. According to experts, infidelity and
                    affairs are the main reason for marital break-ups. </p>

                <p>Making a marriage work after infidelity can be very tough. Infidelity can rock the very
                    foundation of marriage, making the road to recovery a very tough one. It is not easy for the
                    partner who has been faithful in the marriage to forgive a partner who has cheated and broken
                    trust. </p>

                <p>The faithful partner usually feels heartbroken, angry, and experiences a deep sense of loss of
                    faith and trust. When these feelings do not ease out with time or even after making amends,
                    affairs usually lead to divorce or separation. </p>

                <p>While making a marriage work is an uphill task as it is, it is even tougher to make it work after
                    an incident of infidelity has occurred. It requires great amount of emotional strength,
                    patience, and trust to forgive the cheating partner and restore the trust and faith all over
                    again. </p>

                <p>Similarly, the cheating partner will have to resolve their emotional issues that led to the
                    affair in the first place. While it may seem tough, many couples have successfully made their
                    marriage work after an affair. </p>

                <h3>Take the Time to Grieve</h3>

                <p>Whether or not you should forgive a cheating spouse can be a tough decision to make under
                    emotionally trying circumstances. Therefore, before making this decision, give yourself the time
                    to understand what has happened and allow yourself to grieve. </p>

                <p>It is important to be more aware of your emotions around this time rather than run away from
                    them. The more you realise what you are feeling and why you are feeling it, the better you will
                    be able to address these issues with your partner at a later point in time. </p>

                <p>Even the cheating partner should take some time out and analyse why they did what they did and
                    how they can put the infidelity behind them. Since emotions may run high during this time, it is
                    best to sort out these emotions on your own before discussing them with your partner. </p>

                <p>More often than not, couples do not take any time alone and confront each other too soon, leading
                    to harsh exchange of words and bitter arguments which may damage the marriage irreparably. </p>

                <h3>Discuss the “As is” Situation</h3>

                <p>If the faithful partner wants to give the marriage a second chance, they need to let their
                    partner know about it. In addition, the faithful partner also needs to let the other partner
                    know how they feel. </p>

                <p>Similarly, if the cheating partner wants to end the affair, they need to tell their partner about
                    it. In addition, they must attempt to understand the pain and hurt experienced by their partner
                    and assure their partner that they will end the new relationship completely. </p>

                <h3>Resolve Marital Issues</h3>

                <p>Very often, couples feel so bitter about their marriage after the affair is disclosed that they
                    do not know how to talk to each other about their concerns. Every discussion turns into an
                    argument and issues remain unresolved. </p>

                <p>For that reason, before you and your partner even think about talking to each other about the
                    affair or your marriage, it is important to think rationally and calmly. If you both want the
                    marriage to work, you will need to put infidelity behind you and accept the circumstances while
                    attempting to change your marriage for the better. </p>

                <p>The faithful partner will need to know the reason why the cheating partner committed adultery.
                    Therefore, during the discussion stage, partners need to be brutally honest with each
                    other. </p>

                <p>When you have identified the real issues in your marriage, you need to address these issues and
                    resolve them in such a manner that they do not occur again. Many couples find counselling
                    helpful during this time because a counsellor can give an impartial and neutral perspective to
                    the marital problems. </p>

                <h3>Be Patient</h3>

                <p>Reviving the lost trust, love and commitment takes time. Therefore, it is important for both the
                    partners to remain patient throughout the process. </p>

                <p>Trust and love cannot be rebuilt overnight. It may take a few months or even a few years for
                    things to completely return to a state of normalcy. However, your resilience and perseverance
                    will pay off if you keep working on your marriage. </p>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Article8;