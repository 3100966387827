import React from "react";
import ContentRight from "../../components/ContentRight";


function Article() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Private Investigation</h2>

                <p>Relationships have become very complicated these days. Trust has become a rare commodity. It is
                    common for couples to find themselves in a state of confusion when doubt creeps in. Often the
                    situation is such that open communication is not possible. What do you do during such
                    times? </p>

                <p>When you feel that you need to know some information but find it impossible to obtain it from
                    your partner, hiring a private eye can be a good idea. Private investigators are professionals
                    who know how to obtain the much needed information. They may use legal ways to uncover the truth
                    about hidden infidelity, hidden bank accounts, or other information that you might be interested
                    in. </p>

                <h3>Investigating Adultery Cases</h3>

                <p>If you are seeking divorce due to adultery, you will need to provide the adequate evidence in
                    court unless your partner explicitly admits to having committed adultery. Private investigators
                    are extremely useful when you want to know about your partner’s whereabouts in adultery
                    cases. </p>

                <p>The investigators may keep a record of your partner’s movements as well as other information that
                    can help to prove the affair in court. Therefore, you would have a better chance of proving the
                    affair in court because evidence would be readily available. </p>

                <p>There are times when people move out of their family home after separation but do not tell their
                    partner about their affair. If you suspect that the separation has anything to do with an
                    affair, you can hire a private eye to obtain the requisite proof. </p>

                <p>A professional private investigator will be keeping in mind the Data Protection Act and related
                    laws while carrying out the investigation activities. For example, if you want to bug your
                    partner’s phone, you will be better off doing so yourself rather than asking a private eye to do
                    so. However, you may use your private investigator’s knowledge and experience to find out where
                    you can buy a good bugging device. </p>

                <h3>Investigating Unreasonable Behaviour</h3>

                <p>When people file for a divorce citing unreasonable behaviour as the reason, they do not have to
                    provide evidence in court unless the other party completely defends the case. In such cases,
                    people tend to hire a private investigator. </p>

                <p>A private investigator can help you to prove reasons such as verbal abuse, drinking and driving,
                    inappropriate friendship with the member of the opposite sex or neglecting the family. Most
                    people feel very reassured after hiring a private eye in such cases because it relieves them of
                    the stress of having to gather the evidence themselves. </p>

                <h3>Exposing Hidden Assets </h3>

                <p>When a divorce case gets bitter, it is common for partners to doubt each other’s intentions with
                    respect to financial settlement. Doubts about hidden bank accounts and hidden incomes are common
                    during divorce. A private investigator may use several techniques to find out about the missing
                    information. You and your investigator need to discuss the investigation techniques that are
                    within the legal scope. </p>

                <h3>Other Information</h3>

                <p>Your private investigator might need to find out about other background information such as
                    family ties and other connections that might have an impact on your divorce case. The private
                    eye might also find out about previous partners, whether the partner has had a criminal record,
                    or information that might be directly or indirectly related to the case. </p>

                <h3>Hiring Costs</h3>

                <p>Private investigators usually tend to charge their clients a daily or hourly rate but in some
                    cases, they might agree to obtain a piece of information at a flat rate. Most private
                    investigators do not charge their clients for equipment costs since they tend to have their own
                    equipment. However, they might charge their client for some unusual equipment which might be
                    applicable to a specific case only.
                </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article;