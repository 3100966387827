import React from "react";
import ContentRight from "../../components/ContentRight";


function Article() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Citizens Advice and Law Centres</h2>

                <p>Divorce can be a painful and emotionally taxing experience. In addition, it is also a very costly
                    process. In the current financial crunch, most people think about maximising on free yet
                    professional advice when it comes to divorce. Thankfully, people can turn to Citizens Advice
                    Bureau and Law Centres for help as they provide free as well as impartial advice on all aspects
                    of divorce and separation. </p>

                <p>Divorce has so many legal aspects attached to it that many people feel extremely confused about
                    the type of issues they need to address. If you have questions about legal forms, legal aid, or
                    anything related to divorce, you can obtain such details from these centres. These Law Centres
                    also provide free mediation service, legal advice, and other services. </p>

                <h3>The Citizens Advice Bureau</h3>

                <p> If you want help with your legal and financial affairs associated with divorce, the Citizens
                    Advice Bureau would be the best place to start. In addition to providing legal and financial
                    help, they also have information about resources for emotional support in your area. </p>

                <p>It would be a good idea to prepare a list of questions you intend to ask at the Bureau before you
                    call or go for an appointment. The Citizens Advice Bureau has a lot of information about all
                    aspects of divorce and separation. Therefore, make sure that you try to get as much detail as
                    possible during your session. </p>

                <h3>Separation or Divorce </h3>

                <p>It is common for people to feel confused when they think about separating from their partner.
                    People are unsure whether they should opt for a formal divorce, sign a Separation Agreement, or
                    separate informally. </p>

                <p>You will need to decide which course of action best suits your circumstances. Once you have
                    decided what you want, you will need to address important issues such as division of property,
                    child care, tax and benefits, and maintenance. </p>

                <h3>Practical Issues to be addressed</h3>

                <p>The Citizens Advice Bureau will address issues such as listing the people whom you need to
                    contact during your divorce including your council tax office, landlord, insurance companies,
                    and children’s school. </p>

                <h3>Reason for Separation </h3>

                <p>You will need to state your grounds for divorce. In England and Wales, the acceptable reasons for
                    a divorce are adultery, unreasonable behaviour, two years separation if both the partners agree
                    to divorce, five years separation in case of contested divorce, and abandonment. </p>

                <p>The Bureau’s website has detailed information on divorce and separation. If you want to find your
                    local bureau, you can search the website for the same. </p>

                <h3>Law Centres</h3>

                <p>Law Centres are a good place to find free, impartial advice on legal issues associated with
                    divorce and separation. These Law Centres have lawyers who can help you draft legal documents
                    including Separation Agreements, Divorce Petitions, and legal order applications relating to
                    property and childcare. </p>

                <p>Many people cannot afford the expensive divorce process. If you think that you do not have the
                    money to hire the services of a lawyer, or cannot manage to get legal aid, it would be a good
                    idea to contact your local Law Centre. </p>

                <p>The lawyers at the Law Centre have the requisite experience and expertise in divorce and family
                    law and will be able to offer free advice to you on all the legal matters associated with your
                    divorce case. </p>

                <p>Make sure that you make a list of issues before your discussion including issues relating to
                    family home, child custody issues, and financial settlement. </p>

                <h3>Mediation at Law Centres</h3>

                <p>Law Centres provide mediation services to divorcing couples. These services too are free of
                    charge. The mediation sessions can help you and your partner agree on a divorce settlement
                    without having to hire expensive lawyers or ending up in a situation where the courts get to
                    decide your fate. </p>

                <p>Mediation is a good way to maintain a cordial relationship with your partner and your family even
                    during the divorce process. In addition, since the meditation process reduces bitterness
                    associated with divorce to a very large extent, partners are able to share an amicable
                    relationship even after divorce. This can be especially useful if the divorcing couple has
                    children. </p>

                <p>The experts at the Law Centre can explain all the legal issues and help you understand your
                    rights and the best possible course of action. </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article;