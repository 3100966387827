import React from "react";
import ContentRight from "../../components/ContentRight";


function Article6() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Dividing Debt during Separation</h2>

                <p>While many people think about how they should go about dividing their assets during Separation,
                    not many people think about how they should go about dividing their debts. However, it is
                    important to remember that dividing your debts can sometimes be trickier than dividing your
                    joint assets. For that reason, make sure that you spend adequate time with your Solicitor as
                    well as your partner while deciding how the marital debts need to be divided.</p>

                <h3>What Kind of Debt Need to be divided During Separation? </h3>

                <p>Many people find it very hard to divide debt during Separation because the process can be
                    complicated. Your debt could constitute a minor or a major part of your household expense. The
                    expense is bound to be larger if you have a mortgage on your house. </p>

                <p>If you have a car loan or other short-term loans as well, your fixed expenses increase to a very
                    large extent. If these fixed expenses are not divided between you and your partner accurately
                    during Separation, they can affect your financial circumstances for the rest of your life. </p>

                <p>Many people think that they will be relieved of their responsibility of a joint debt if the court
                    orders their spouse to pay for the debt on their own. This is a common misconception as it is
                    simply not the case. </p>

                <p>While it is true that the court might order one of the partners to pay the joint debt, it cannot
                    prevent the creditor from holding you liable for the payment of debt dues. </p>

                <p>It is only when a joint contract is changed that the liability changes. Therefore, unless you and
                    your partner get the joint contract changed, you will be responsible for the payment of that
                    debt. </p>

                <p>So, what kind of debts needs to be divided? All the debts that you incurred jointly need to be
                    divided at the time of Separation. One of the most common joint debts is a mortgage loan.
                    Therefore, you need to make sure that you are thoroughly aware of how the loan amount will be
                    settled. </p>

                <p>In addition, if you and your partner have taken a joint care loan or hold joint credit cards, you
                    need to know how these debts will be divided. It would be a good idea if you make a list of all
                    the joint loans to understand your joint liabilities in a better manner. </p>

                <h3>How to Divide Joint Debt during Separation? </h3>

                <p>The best way to divide a joint debt is to pay off the debt before the Separation. This is the
                    only failsafe solution to avoid any kind of debt collection troubles at a later stage. By paying
                    off your joint debt before Separation you ensure that your future finances are protected. </p>

                <p>Even though you may not be sure about the final division of assets post Separation, at least you
                    will be sure that you will not need to take care of any joint liability post Separation by
                    settling all the joint debts before Separation. </p>

                <p>There are times when people do not have adequate cash to pay off their joint debt. In such cases,
                    you may consider selling off some of your joint assets to accumulate the necessary cash. </p>

                <p>Often the best thing to do is to sell off the joint asset that came as a result of the joint
                    loan. People usually tend to adopt this approach when it comes to the family home. Therefore, if
                    you have taken a joint loan on your family home, it would be best if you sell off your home and
                    divide the proceeds amongst yourselves. </p>

                <h3>Useful Tips for Handling Joint Debt</h3>

                <ul>
                    <li>Cancel all your joint credit cards as soon as you decide to Separation so that you are not
                        liable for your spouse’s expenses.
                    </li>
                    <li>Separate finances as soon as possible.</li>
                    <li>If you and your partner have joint bank accounts, request your bank to cancel these accounts
                        or withdraw any authorisation provided to your spouse for operating this account.
                    </li>
                    <li>Debt settlement can be tough. If you need to, you may hire a mediator to resolve the
                        differences with your partner.
                    </li>
                </ul>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article6;