import React from "react";
import {Link} from "react-router-dom";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Archive() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Articles Archive</title>
                <meta id="meta-description" name="description" content="Archive contains a list of articles about separation agreements" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online, articles, archive" />
                <meta id="og-title" property="og:title" content="Articles Archive" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h2>Articles Archive</h2>
                    <ul>
                        <li><Link to="article.html">Private Investigation</Link></li>
                        <li><Link to="article2.html">Changes Likely in the Family Law to Reinstate the Importance of
                            Marriage</Link></li>
                        <li><Link to="article3.html">Citizens Advice and Law Centres</Link></li>
                        <li><Link to="article4.html">Conflicts in a Marriage and How to Deal with Them</Link></li>
                        <li><Link to="article5.html">Separation and Contact Rights</Link></li>
                        <li><Link to="article6.html">Dividing Debt during Separation</Link></li>
                        <li><Link to="article7.html">How to Tell Your Kids about Your Separation</Link></li>
                        <li><Link to="article8.html">How to Cope with Infidelity in a Marriage</Link></li>
                        <li><Link to="article9.html">Marriage Guidance Counselling</Link></li>
                        <li><Link to="article10.html">Pros and Cons of a Trial Separation</Link></li>
                    </ul>
                </div>
            <ContentRight />
        </div>
    );
}

export default Archive;