import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";


function PrivacyPolicy() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Personalised service</title>
                <meta id="meta-description" name="description" content="Our personalised service provides customers with a unique, tailored Separation Agreement" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, Personalised Service, Quick Separation, FREE Separation Advice, Online Separation, Separation Agreement Experts, Separation Agreement Advice, Separation Agreement Information" />
                <meta id="og-title" property="og:title" content="Personalised service" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h2>Privacy Policy</h2>
                <div className="box22 h-auto row">
                    

                   <div className="col-lg-10 col-7">
                        <p>Separation Agreement UK is committed to protecting the identity and privacy of our
                            users.</p>

                        <p>We will only use information collected in the process of transactions in a strictly
                            lawful manner.</p>

                        <p>Separation Agreement UK will not use client emails to contact clients once the
                            transaction has been completed other than in accordance with a query from them.*</p>

                        <p>Email addresses will not be provided to any third party.</p>

                        <p>Users should be aware that Separation Agreement UK retains no information relating to
                            clients' credit card details. This information is not known to Separation Agreement and
                            is only retained by the Merchant Service Provider.</p>

                        <p id="note">* Clients may receive emails regarding related products or services they may
                            find of interest.
                        </p>
                   </div>

                   <div className="col-lg-2 col-4">
                        <img src="images/badges2.png" className="guarantee_image_2" alt="badges" title="badges"/>
                    </div>

                </div>

                <div className="bottom d-flex justify-content-between mb-4 pt-2">
                    <div className="l_btn">
                        <Link to="order_now.html" className="btn_1" title="Order Now">
                            <span className="b_inner">Order Now</span>
                        </Link>
                        
                    </div>
                    <div className="r_btn">
                        <Link to="services.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </Link>
                    </div>
                </div>
                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <Link to="services.html">
                    <div className="index_btn2"></div>
                </Link> */}
                
            </div>
            
            <ContentRight/>
        </div>
    );
}

export default PrivacyPolicy;