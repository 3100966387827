import React, { useState } from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import MetaTags from "react-meta-tags";


function PersonalService() {

    const [popOvervalues, setPopOverValues] = useState({
        pop_over_open: false,
        popover_header: "",
        popover_message: "",
        textarea_value: "J1 New Road, Newtown"
    });

    const togglePopover = () => {
        popOvervalues.pop_over_open = !popOvervalues.pop_over_open;

    }

    const popOver = (primo, secondo) => {
        setPopOverValues({
            ...popOvervalues,
            popover_header: primo,
            popover_message: secondo,
            pop_over_open: true
        });
    }


    // close all popover
    const closeAllPopover = () => {
        setPopOverValues({
            pop_over_open: false
        });
    }

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Personalised service</title>
                <meta id="meta-description" name="description" content="Our personalised service provides customers with a unique, tailored Separation Agreement" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, Personalised Service, Quick Separation, FREE Separation Advice, Online Separation, Separation Agreement Experts, Separation Agreement Advice, Separation Agreement Information" />
                <meta id="og-title" property="og:title" content="Personalised service" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h2>Personalised Service</h2>

                <p>If you would rather not get a Solicitor involved we will provide you with your own Separation
                    Agreement drafted using your information by an experienced expert. Fill in our quick and simple
                    questionnaire and your Separation Agreement will be drafted for you. Your package includes all
                    essential information on Separation Agreements. <b>FREE</b> advice will be available via email
                    and over the phone. </p>

                <p>The Essential Guide to Separation Agreements will provide you with all the information you need
                    to know about your own agreement. The guide has an RRP of £19.99 but for our customers we will
                    send you this invaluable piece of literature absolutely <b>FREE</b>. As with all our products
                    the Personalised Separation Agreement is covered by our 100% money back <b>GUARANTEE</b>. </p>

                <p><b>Order yours now and get:</b></p>

                <ul>
                    <li><b>Your Separation Agreement completed for you</b> - fast and stress-free</li>
                    <li><b>Drafted Agreement</b> - no loopholes, for a fraction of the High St price</li>
                    <li><b>FREE Email Support</b> - help and advice, guaranteed response within 1 hour</li>
                    <li><b>FREE Phone Support</b> - immediate help and advice on any related subject</li>
                    <li><b>FREE Updates</b> - notification of law changes or requirements</li>
                    <li><b>FREE Guide</b> - The Essential Guide to Separation Agreements (RRP £19.99)</li>
                    <li><b>Discounted Will</b> - show your loved ones they will be provided for</li>
                    <li><b>GUARANTEE</b> - no-quibble, 100% money-back guarantee</li>
                </ul>

                <p>You don't need the services of a Solicitor and you won't need to fill in complicated legal forms
                    or understand Solicitors' jargon; all you need are your personal details and a few minutes of
                    your time - your Separation Agreement can be completed for you and downloaded or sent to you by
                    email.</p>

                <p>For our experts to assess your case and produce a completed Separation Agreement you only need to
                    complete a simple online questionnaire.</p>

                <p>The questionnaire only takes minutes to complete but the simple click ‘save’ option allows for
                    you to leave the questionnaire and return to complete it at your own pace when you have the
                    time.</p>

                <p>Once you have filled in the questionnaire the information is used to draft a legally-binding
                    Separation Agreement document which will then be sent to you by email within 48 hours.</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>

                <hr/>

                <h3>Preview Our Questionnaire</h3>

                <p>To automatically produce a completed Separation Agreement you only need to fill in a simple
                    online questionnaire.</p>

                <p>Click on [help] for an explanation of how to answer the relevant question.</p>

                <p>Below is an example of our simple, easy-to-use questionnaire:</p>

                <div className="example_qnnaire_border">
                    <table className="qnnaire" width="100%" align="center" border="0" cellPadding="13"
                           cellSpacing="1">
                        <tbody>
                        <tr>
                            <td className="backcell22" >What is the name of the first party?</td>
                            <td className="backcell22" ><img src="images/right.gif" alt="right gif"/></td>
                            <td className="backcell4" align="center">
                                <input name="textfield" value="John Smith" className="form-control" size="28" maxLength="30" readOnly type="text"/></td>
                            <td className="backcell22b" >
                                <a href="#/" id="mypopover" onMouseOut={() => closeAllPopover()} onClick={() => popOver("Help Info", "Please enter full name")} className="popoverBox" >
                                    [help]</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell22">What is the address of the first party?</td>

                            <td className="backcell22"><img src="images/right.gif" alt="right gif"/></td>
                            <td className="backcell4" align="center">
                                <textarea name="textarea"  rows="3" className="form-control" readOnly defaultValue={popOvervalues.textarea_value}></textarea>
                            </td>
                            <td className="backcell22b">
                                <a href="#/" id="mypopover" onMouseOut={() => closeAllPopover()}  onClick={() => popOver("Help Info", "Please enter the current/previous address")} className="popoverBox" >[help]</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="backcell22">What is the name of the second party?</td>
                            <td className="backcell22"><img src="images/right.gif" alt="right gif" /></td>
                            <td className="backcell4" align="center">
                                <input name="textfield3" className="form-control" value="Joan Roberts" size="28" maxLength="30" type="text" readOnly/></td>
                            <td className="backcell22b">
                                <Popover
                                    placement="top"
                                    isOpen={popOvervalues.pop_over_open}
                                    target="mypopover"
                                    toggle={togglePopover}>
                                    <PopoverHeader>{popOvervalues.popover_header}</PopoverHeader>
                                    <PopoverBody>
                                        {popOvervalues.popover_message}
                                    </PopoverBody>
                                </Popover>
                                <a href="#/"  id="mypopover" onMouseOut={() => closeAllPopover()}   onClick={() => popOver("Help Info", "Please enter full name")} className="popoverBox" >[help]</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <p>You can complete the questionnaire in minutes or simply click the ‘save’ button and return to
                    complete it at your own pace and in your own time.</p>

                <p>Once you have filled in the questionnaire the information is used to draft a legally-binding
                    Separation Agreement document which will then be sent to you by email within 2-3 working
                    days.</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>

                <hr/>

                <h3>Price Comparison Chart</h3>

                <p>You only want to make one Separation Agreement, so choose <b>the highest quality, most
                    comprehensive, value-for-money online solution available in the UK</b>. Compare other
                    leading services and their prices:</p>

                <div className="table-container">
                    <table className="compare" border="0" cellPadding="2" cellSpacing="1" align="center">
                        <tbody>
                        <tr align="right">
                            <th className="comparison_table_ths" width="25%">Services</th>
                            <th className="comparison_table_ths" width="15%">High Street<br/> Solicitor</th>
                            <th className="comparison_table_ths" width="15%">Shortlands<br/> Solicitor</th>
                            <th className="comparison_table_ths" width="15%">Divorce<br/> Online</th>
                            <th className="comparison_table_ths" width="15%">Click<br/> Docs</th>
                            <th className="comparison_table_ths" width="15%">Our<br/> Service</th>
                        </tr>

                        <tr>
                            <td className="bar">Completed Online</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Supervised Solicitor<br/>Service</td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Phone Support</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span>9am – 9pm</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span>9am – 9pm</span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Updates</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE Consultation</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">FREE eBook</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Discounted Will(s)</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="tick"></span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>
                            <td className="bar">Guarantee</td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="main"><span className="cross">x</span></td>
                            <td className="green"><span className="tick1"></span></td>
                        </tr>
                        <tr>

                            <td className="costb">Costs</td>
                            <td className="cost">£1000+</td>
                            <td className="cost">£350.00</td>
                            <td className="cost">£75.00</td>
                            <td className="cost">£46.06</td>
                            <td className="costg"><span className="white"><strong>£67</strong></span></td>
                        </tr>

                        </tbody>
                    </table>
                </div>


                <p>You can see from the above comparison chart that Separation Agreement UK
                    offers <b>The UK's Premier Service</b>. Separation Agreement UK are an expert online
                    legal service - having considerably fewer overheads than a high-street Solicitor - this
                    keeps costs down to a minimum, allowing savings to be passed on to you, the customer.
                </p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>

                <hr/>

                <h3>See What You Can Save By Using Separation Agreement UK</h3>

                <p>A High Street Solicitor may charge you £195 per hour (plus VAT)</p>

                <ul>
                    <li>Solicitor Fees (incl. VAT) £1121.25</li>
                    <li>£300 court fees</li>
                    <li>Total costs = <b>£1421.25</b></li>
                </ul>

                <p>Compare the cost of our <b>Separation Agreement</b>:</p>

                <ul>
                    <li>Our Separation Agreement: £67</li>
                    <li>No court fees required</li>
                    <li>Total Costs £67</li>
                </ul>

                <p>That’s a <b>TOTAL SAVING OF £1354.25</b> when using our service!</p>

                <p><b>PLUS</b> – Buy any of our Separation Agreement options by credit or debit card now
                    and we’ll give you a new Will for just £10 (normally) £39 with FREE updates for
                    life. </p>

                <p><b>ALSO</b> – Receive “The Essential Guide to Separation Agreements” by Jayne McGill
                    (normally £19.99)</p>

                <p><b>ABSOLUTELY FREE</b></p>

                <p>Start your Separation Agreement by clicking the “order now” button...</p>

                <div className="order_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
            </div>
            <ContentRight/>
        </div>
    );
}

export default PersonalService;