import React from "react";
import ContentRight from "../../components/ContentRight";


function Article5() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Separation and Contact Rights</h2>

                <p>Contact rights are a part of the separation process when there are children involved. Ideally,
                    couples should try to reach an agreement about contact and residency before they initiate the
                    divorce process. </p>

                <p>However, the bitterness associated with the separation is often so immense that couples are
                    unable to reach a mutual consensus about any of the important issues. In such cases, a court
                    officer may be appointed to recommend a decision to the court or help the couple. </p>

                <h3>Reach a Mutual Consensus </h3>

                <p>Children are very vulnerable during a separation or divorce. Therefore, it is every parent’s
                    responsibility to minimise the stress and anxiety in their children’s’ lives. </p>

                <p>If you and your partner have decided to separate, you need to ensure that you explore all the
                    options available to you with your partner so that your children’s lives are disrupted to the
                    least possible extent. </p>

                <p>Often, when couples are unable to reach an agreement on their own, seeking the help of a
                    professional mediator proves to be helpful. Mediators are able to help the couples discuss the
                    issues related to their separation in a productive manner. If the couples deviate from the
                    subject to mindless blame games, a mediator is able to help the couple get back on track and
                    focus on the real issues. </p>

                <p>It usually takes a couple three to five sessions to agree on common terms after which, a couple
                    can produce a separation agreement. Mediation can result in establishing a successful mutual
                    agreement.</p>

                <h3>Contact Rights- Legal Aspects</h3>

                <p>According to UK law, when a couple separates, they both have parental responsibility towards
                    their children. If a couple is unmarried and they have a child, only the mother has automatic
                    rights towards the child and a father does not have an automatic say in the matters regarding
                    the child’s life. </p>

                <p>Since 2002, an unmarried dad can have parental rights towards the child if he registers the birth
                    of the child along with the mother. </p>

                <p>Parental responsibility can also be acquired if both the partners sign a Parental Responsibility
                    Agreement. However, it is important to understand that fathers can be contacted by the Child
                    Support Agency for financial support, if required. Deductions are usually made from the father’s
                    pay depending on how much time the father spends with the children. </p>

                <h3>The Court Procedure</h3>

                <p>Courts encourage couples to come up with an agreement on their own. It is only when couples are
                    unable to do so that they make an order. </p>

                <p>Firstly, the court will arrange for a conciliation appointment between you and your partner to
                    ensure that you agree on the terms of contact, payments and residency. If this meeting does not
                    lead to an agreement, they will arrange for you to see a CAFCASS officer. </p>

                <p>If you and your partner are unable to reach an agreement even after that, the CAFCASS officer
                    will take a statement from each of you and make a recommendation to the court. </p>

                <p>Since the CAFCASS officer’s recommendation holds a lot of importance in the court, it would be
                    best to cooperate with the officer. At the end of the process, either one or both the partners
                    will be awarded a residency order. One of the partners might receive a contact order, which
                    gives details about the contact rights. </p>

                <h3>Some Factors that Influence Court’s Decision</h3>

                <ul>
                    <li>Child’s wishes and feelings. The older the child, the more the consideration toward their
                        feelings.
                    </li>
                    <li>Your child’s emotional, physical, and educational needs.</li>
                    <li>Court’s primary concern is to minimise the disturbance caused to the children during the
                        process. Therefore, the court is likely to accept an arrangement that only minimally
                        disturbs a child’s current living arrangement.
                    </li>
                    <li>If your child’s safety is a concern or if he or she has special needs.</li>
                    <li>Parents’ capability of taking care of the child. If the court feels that one of the partners
                        is not capable of taking care of the child, the custody will be awarded to the other parent.
                    </li>
                </ul>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article5;