import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";

function FreeEBook() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>FREE eBook</title>
                <meta id="meta-description" name="description" content="The Essential Guide to Separation Agreements" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online, book, free" />
                <meta id="og-title" property="og:title" content="FREE eBook" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h2>FREE eBook</h2>

                <table width="100%" border="0" cellSpacing="0" cellPadding="0" >
                    <tbody>
                    <tr>
                        <td height="10" colSpan="2"></td>
                    </tr>
                    <tr>
                        <td>
                            <p align="justify"><strong>The Essential Guide to Separation Agreements</strong> Jayne
                                McGill ©2005</p>
                            <p align="justify">This advice-filled guide covers every aspect of separation agreements
                                including:</p>
                            <p align="justify">'<em>The 6 Golden Rules of Separation Agreements</em>'</p>
                            <p align="justify">Subjects also covered include:</p>
                            <ul>
                                <li>Division of Property</li>
                                <li>Maintenance</li>
                                <li>Debts</li>
                                <li>Child Support Provisions</li>
                                <li>Personal Possesions</li>
                                <li>Pensions</li>
                                <li>Insurance</li>
                                <li>Bills</li>
                                <li>And much, much more…</li>
                            </ul>
                        </td>
                        <td valign="top"><img src="images/book-separation.png" alt="book" border="0"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <p>Many people believe that the only way to fix their marital problem is to get an instant divorce. A
                    divorce is not the only answer. To understand and weigh up your other options to your separation,
                    read the comprehensive, step-by-step eGuide: The Essential Guide to Separation Agreements.</p>

                    <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                        <div className="l_btn">
                            <Link to="order_now.html" className="btn_1" title="Order now">
                                <span className="b_inner">Order Now</span>
                            </Link>
                            
                        </div>
                        <div className="r_btn">
                            <Link to="services.html" className="btn_1" title="Read More">
                                <span className="b_inner">Read More</span>
                            </Link>
                        </div>
                    </div>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
            </div>
            <ContentRight/>
        </div>
    );
}

export default FreeEBook;