import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import {Link} from "react-router-dom";



function Wills() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Discounted Will</title>
                <meta id="meta-description" name="description" content="Purchase a Separation Agreement NOW and you'll receive your completed Last Will and Testament at an EXCLUSIVE Special Offer price of £10 (usually £39)" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online, will, free" />
                <meta id="og-title" property="og:title" content="Discounted Will" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h2>Discounted Will</h2>

                    <p>Is your spouse still featured in your Will? Do you even have a Will?</p>

                    <p>If you are expecting a significant change in lifestyle you will probably want to make a new Will.
                        Your Last Will and Testament is a vital legal document, so it is important that your Will is
                        prepared correctly. </p>

                    <p>Purchase a Separation Agreement <b>NOW</b> and you'll receive your completed Last Will and
                        Testament at an <b>EXCLUSIVE Special Offer </b>price of £10 (usually £39). This quick, simple
                        and affordable Will solution allows you to change your Will whenever you want, and as many times
                        as you require, <b>COMPLETELY FREE</b>.</p>

                    <p>Often after a separation the thought of changing the details in your Will can be the furthest
                        thing from your mind. If you want to change the details of your Will now is the time to do it.
                        Should the unthinkable happen your spouse could get left with everything.</p>

                    <p>Without a Will in the event of anything happening to you, your spouse could get your possessions
                        even with a Separation Agreement in place. Make sure that you have control over your assets and
                        have secured the future by having a legally binding Will drafted with us at the discounted cost
                        of just £10.</p>

                    <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                        <div className="l_btn">
                            <Link to="order_now.html" className="btn_1" title="Order now">
                                <span className="b_inner">Order Now</span>
                            </Link>
                            
                        </div>
                        <div className="r_btn">
                            <Link to="services.html" className="btn_1" title="Read More">
                                <span className="b_inner">Read More</span>
                            </Link>
                        </div>
                    </div>

                    {/* <a href="order_now.html">
                        <div className="index_btn1"></div>
                    </a>

                    <a href="services.html">
                        <div className="index_btn2"></div>
                    </a> */}
                </div>
                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="personal-service.html">
                    <div className="index_btn2"></div>
                </a> */}
                
            <ContentRight/>
        </div>
    );
}

export default Wills;