import React from "react";
import ContentRight from "../../components/ContentRight";


function Article4() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Conflicts in a Marriage and How to Deal with Them</h2>

                <p>Making a marriage work is not easy. Everyone is different and when two people stay together,
                    these differences become more apparent. However, marriage is all about dealing with differences
                    and loving your partner despite these differences. </p>

                <p>You and your partner are bound to have conflicts from time to time. However, how you deal with
                    these conflicts can make or break your marriage. </p>

                <h3>Dealing with Conflicts</h3>

                <p>It is important to deal with conflicts in a healthy manner. Often, married couples start feeling
                    very negative about their relationship when they have constant arguments about the same issues.
                    Sadly, it is common for couples to ignore these arguments rather than solving them and
                    discovering what each of them feels. </p>

                <p>It is important to know that not all fights are bad in a marriage. Arguments in a marriage can
                    help the partners know more about each other’s deepest feelings. </p>

                <p>In our day-to-day lives, we do not get to discuss certain things that may mean a lot to us. It is
                    during these arguments that couples can learn about each other’s philosophy, way of thinking and
                    reacting and in the process learn a lot about each other. Ideally, after each conflict, you
                    should feel closer to each other. </p>

                <p>Couples in healthy marriages claim that they feel better about each other and their relationship
                    after resolving their conflict. However, couples can only feel this way when a conflict is
                    resolved in a productive manner and not by playing a blame-game. </p>

                <h3>Respect Your Partner</h3>

                <p>It is normal to feel agitated and be mad at your partner when you are having an argument.
                    However, this is no excuse to be disrespectful towards your partner. If you and your partner
                    start speaking to each other disrespectfully during the course of the argument, chances are that
                    you will never be able to resolve the issue. </p>

                <p>Listen to your spouse patiently when they are trying to explain their side. At the same time,
                    respond and let your partner know about how you feel when your partner is ready to listen. </p>

                <p>Many times, one of the partners becomes reticent during an argument and the other partner is
                    unable to understand their point of view because of this. For that reason, it is important to
                    keep the lines of communication open during a conflict. </p>

                <h3>Never Assume</h3>

                <p>This is the most common mistake that most married couples tend to commit. Do not assume what your
                    partner is thinking or their motivation for saying or doing something. Marriage is all about
                    honesty. Therefore, if you are not sure about why your partner did or said something, ask them
                    to explain it to you more clearly. </p>

                <p>Doing so prevents misunderstandings and miscommunication to a very large extent. Always remember
                    that during a conflict, the last thing you want is a misunderstanding. Do not make
                    generalisations because they are seldom true. Therefore, do not use affirmative language such as
                    “never” or “always” when you are in disagreement with your partner. </p>

                <h3>Be Polite</h3>

                <p>While you may be tempted to lash out your anger at your partner during the course of the
                    conflict, remember that harsh words, once spoken, cannot be taken back. </p>

                <p>When we say something harsh, mean or derogatory to someone, the words stay in the mind of the
                    receiver even long after the argument is over. This way, the bitterness associated with the
                    fight never truly leaves the mind. Do not use bad language during an argument and do not label
                    your partner by using disparaging terms. </p>

                <h3>Forgive and Forget</h3>

                <p>Just as you cannot do without fighting with your partner when you stay under the same roof, you
                    cannot do without letting go and forgiving your partner once the argument is resolved. Many
                    people keep harping about the argument and referring to it even after the issue has been
                    resolved. However, doing so only makes matters worse and leads to another argument. </p>

                <p>Make sure that you forget about the fight and return to being your normal self after the issue is
                    resolved. How you and your partner argue, learn from the argument, and move forward can make all
                    the difference. So, here is wishing you a very happy argument! </p>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article4;