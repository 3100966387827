import React from "react";
import ContentRight from "../../components/ContentRight";


function Article7() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>How to Tell Your Kids about Your Separation</h2>

                <p>When parents think about separating, the first thing that comes to mind is how children will take
                    the news. Undoubtedly, the task at hand will be tough. Children can get very emotional when it
                    comes to their parents’ divorce. Therefore, as a parent, you will need to ensure that you are
                    there for your child emotionally. </p>

                <p>For a child, a divorce means a complete change in family life. However, if you plan
                    appropriately, the adverse effects of a separation on the children can be reduced. </p>

                <p>Many parents find it very hard to reach out to their children during this time because they too
                    are going through an emotional upheaval. While it is important for you to concentrate on your
                    emotional issues, it is equally important to take care of your child to ensure that their
                    delicate mental balance is not disturbed by the emotional trauma. </p>

                <h3>How Children React to Divorce</h3>

                <p>Children of different age groups react in a different way when it comes to divorce. Usually, very
                    young children tend to cry more often. On the other hand, teenagers show signs of rebellion and
                    can become short-tempered. </p>

                <p>The reactions also differ according to gender and personality. While some children might react to
                    divorce by becoming more violent in their behaviour, there are others who become completely
                    reclusive and avoid any form of socialising whatsoever. </p>

                <p>It is important for parents to observe changes in their child’s behaviour closely to understand
                    how they can help their child. For example, observe if your child is displaying any behavioural
                    changes at school or if your young child is displaying patterns of sleeplessness. All these
                    reactions occur due to underlying feelings of anxiety and depression.</p>

                <h3>Depression and Anxiety</h3>

                <p>In all probability, your child will be concerned about his or her future living arrangements.
                    Children feel very anxious when they do not know whom they will stay with and how their
                    relationship with the non-resident parent will be affected. </p>

                <p>Older children are usually good at hiding their feelings because they try to protect their
                    parents by doing so. However, it is important for parents to understand that even if your older
                    child does not clearly state his or her depression about divorce, make sure that you talk to
                    your child openly so that you are able to gather what he or she is actually feeling.</p>

                <p>It is common for children to feel depressed during this time because in a child’s mind their
                    family is falling apart. After all, for a child, a family and family home means the world. While
                    it is natural for every child to feel depressed, make sure that you try to get your child out of
                    the depression as soon as you can. </p>

                <h3>Tips for Ensuring Your Child’s Well-being</h3>

                <ul>
                    <li>When parents talk to their child about their divorce together, a child is able to see the
                        shared sense of concern. Children feel that their parents are still willing to share the
                        responsibility of their welfare when their parents talk to them together about what is going
                        to happen and how things will change. By seeing their parents together, children are better
                        able to understand that while their parents might not be a couple anymore, they are still
                        going to be mom and dad.
                    </li>

                    <li>While it is important for you to talk to your kids about divorce openly, it does not mean
                        that you need to divulge all the unnecessary details. For example, your child does not need
                        to know every detail about the kind of conflicts you and your partner have been having or
                        details about an extramarital affair.
                    </li>

                    <li>It helps when children are kept in their normal routine without making any major changes.
                        For example, try not to change your child’s school or their neighbourhood unless absolutely
                        necessary. Even if you have to bring about major changes, make sure that you do so gradually
                        so that your child has adequate time to adjust to each change.
                    </li>

                    <li>Many times, children assume that their parents’ divorce is somehow their fault. It is
                        important that you assure your child that your decision to divorce has got nothing to do
                        with their behaviour. In addition, make sure that you explain to your child that the
                        decision to divorce is an adult decision and not influenced by what children say or do.
                    </li>

                    <li>Your children will need constant reassurance from your end that you and your partner will
                        continue to love and care for them even after the divorce. The non-resident parent will need
                        to ensure that they maintain regular contact with their children after divorce.
                    </li>
                </ul>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article7;