import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

function Testimonials() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Testimonials</title>
                <meta id="meta-description" name="description" content="What our customers had to say about our Separation Agreements and our marital separation service " />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Agreement, Quick Separation, testimonials, Online Separation Agreement, Agreement Advice, Cheap Separation Agreement" />
                <meta id="og-title" property="og:title" content="Testimonials" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>Testimonials</h1>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt=
                            "Order Now" to="order_now.html" />
                </div> */}

                    <div className="l_btn">
                        <Link href="order_now.html" className="btn_1" title="Order Now">
                            <span className="b_inner">Order Now</span>
                        </Link>
                        
                    </div>
            </div>
            <ContentRight />
        </div>
        );
}

export default Testimonials;