import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";


function Support() {
    return (
        <div className="content_wrapper text-center support-box">
            <MetaTags>
                <title>Support</title>
                <meta id="meta-description" name="description" content="Our team of experts can give guidance on all matters relating to our Separation Agreement process, and can also help you choose which service is right for you" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online" />
                <meta id="og-title" property="og:title" content="Support" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                <h2>Confused about your Separation Agreement?</h2>

                <p className="cb">Last year, over 22,500 people in the UK called us for help and advice initiating
                    their Separation Agreement.</p>

                <p>Our team of experts can give guidance on all matters relating to our Separation Agreement
                    process, and can also help you choose which service is right for you.</p>

                <p className="support_hdr">For free, impartial advice – call us now on free phone:</p>
                <p className="support_telno">0800 440 2512</p>
                <hr/>

                <dl className="row">
                    <dt className="col-sm-12 col-md-6">Email us:
                    </dt>
                    <dd className="col-sm-12 col-md-6 ">
                        <p className="support_email">General Enquiries:<br/><a
                            href="mailto:enquiries@separation-agreement.co.uk">enquiries@separation-agreement.co.uk</a>
                        </p>

                        <p className="support_email">Customer Support:<br/><a
                            href="mailto:                 support@separation-agreement.co.uk"> support@separation-agreement.co.uk</a>
                        </p>

                        <p className="support_email">Comments &amp; Suggestions:<br/><a
                            href="mailto:webmaster@separation-agreement.co.uk">webmaster@separation-agreement.co.uk</a>
                        </p>
                    </dd>
                </dl>

                <dl className="row">
                    <dt className="col-sm-12 col-md-6">Fax us:
                    </dt>
                    <dd className="col-sm-12 col-md-6">
                        <p className="support_faxno">01656 673 361</p>
                    </dd>
                </dl>

                <dl className="row">
                    <dt className="col-sm-12 col-md-6">Write to us:
                    </dt>
                    <dd className="col-sm-12 col-md-6">
                        <p className="support_email">Separation Agreement UK<br/>
                            Fields House<br/>
                            12/13 Old Fields Road<br/>
                            Pencoed<br/>
                            Bridgend<br/>
                            CF35 5LJ</p>
                    </dd>
                </dl>

                <dl className="row">
                    <dt className="col-sm-12 col-md-6">Submit feedback:
                    </dt>
                    <dd className="col-sm-12 col-md-6">
                        <p className="support_email"><a href="contact.html">Click here to contact us</a></p>
                    </dd>
                </dl>
            </div>
            <ContentRight/>
        </div>
    );
}

export default Support;