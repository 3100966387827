import React from "react";
import $ from 'jquery';
import 'bootstrap';
import {Link} from "react-router-dom";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import MetaTags from "react-meta-tags";


// popup alert when clicking on links showing an help message
$(function () {
    $('[data-toggle="popover"]').popover();
})

// show this picture on the side meny when landing in the page.
$(document).ready(function () {
    $(".box-img-menu img").attr("src", "../images/picture9.png");
});


class OrderNow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sErrorMsg: "",
            sErrorMsgTitle: "Check the following:",
            objFrmMain: "",
            arrValidDates: ['YY/DD/MM', 'YYMMDD', 'YYYYMMDD', 'YYYY-MM-DD'],
            iFormatDate: 0,
            sErrIsEmpty: " is required.\n",
            sErrFormat: "Err!",
            sErrNotChoosen: " is not choosen.\n",
            sErrValidateTextMinLength1: " must be at least ",
            sErrValidateTextMinLength2: " characters.\n",
            sErrValidateTextMaxLength1: " can be at most ",
            sErrValidateTextMaxLength2: " characters.\n",
            sErrValidateEmail: " is not a valid e-mail address.\n",
            sErrCompareDatesIsNot: " is not ",
            sErrCompareDatesSameAs: "same as",
            sErrCompareDatesDifferent: "different",
            displayNone: "none",
            monthNames: ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ],
            passwordCharsContent: false,
            amount: '',
            product_ki: '',
            product_title: '',
            product_id: ''
        };
        this.closeAllPopover = this.closeAllPopover.bind(this);
        this.togglePasswordCharsContent = this.togglePasswordCharsContent.bind(this);
    }

    togglePasswordCharsContent() {
        this.setState({passwordCharsContent: !this.state.passwordCharsContent})
    }

    // open the form when selected a package or the opposite when going back to packages list
    closeWillForm() {
        $(".modal").remove();
        if (this.instantWillBox()) {
            $("[name='frmorder']").slideToggle();
            $("#complete_form").toggleClass("d-none");
        }
    }

    // close all popover
    closeAllPopover() {
        this.setState({passwordCharsContent: false});
    }

    // When the user starts to type something inside the password field
    validatePassword(sText, sName) {
        var errorMsg = "";

        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if (!sText.match(lowerCaseLetters)) {
            errorMsg += "- Password must contain at least a lower case letter<br/>";
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if (!sText.match(upperCaseLetters)) {
            errorMsg += "- Password must contain at least a capital letter<br/>";
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (!sText.match(numbers)) {
            errorMsg += "- Password must contain at least a number<br/>";
        }

        // Validate length
        if (sText.length < 8) {
            errorMsg += "- Password must contain at least 8 characters<br/>";
        }
        return errorMsg;
    }

    validateText(sText, sName, iMinLength, iMaxLength) {
        sText = $.trim(sText);
        var errorMsg = "";

        if (sText === "") // If empty
        {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        } else if (sText !== "") // else if to short or to long string
        {
            if (iMinLength !== null && sText.length < iMinLength) {
                errorMsg = "- " + sName + this.state.sErrValidateTextMinLength1 + iMinLength + this.state.sErrValidateTextMinLength2;
            }
            if (iMaxLength !== null && sText.length > iMaxLength)
                errorMsg = "- " + sName + this.state.sErrValidateTextMaxLength1 + iMaxLength + this.state.sErrValidateTextMaxLength2;
        }

        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }

    // 'clock' on order now page
    componentDidMount() {
        setInterval(() => {
            var thisDate = new Date();

            var year = thisDate.getFullYear();
            var month = thisDate.getMonth();
            var day = thisDate.getDate();
            var hour = thisDate.getHours();
            var min = thisDate.getMinutes();
            var sec = thisDate.getSeconds() < 10 ? '0' + thisDate.getSeconds() : thisDate.getSeconds();

            $(".time").html(day + " " + this.state.monthNames[month] + " " + year + " " + hour + ":" + min + ":" + sec);
        }, 1000)
    }

    validateEmail(sEmail, sName) {
        /* emailChecker = verify a valid email */
        let emailChecker = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
        let trimmedEmail = $.trim(sEmail);
        var errorMsg = "";

        if (sEmail === "") {
            errorMsg = "- " + sName + this.state.sErrIsEmpty;
        } else if (sEmail !== "") {
            if ((!emailChecker.test(trimmedEmail))) {
                errorMsg = "- " + sName + this.state.sErrValidateEmail;
            }
        }
        return errorMsg !== "" ? errorMsg + "<br/>" : "";
    }

    // validate the entire form
    validate(e) {
        e.preventDefault();
        let parentThis = this;
        let sErrorMsgFinale = "";
        $(".modal").remove();
        this.setState({sErrorMsgTitle: "Check the following:"});
        let form = document.frmMember;
        sErrorMsgFinale += this.validateText(form.firstName.value, "Name", 2, 20);
        sErrorMsgFinale += this.validateText(form.lastName.value, "First name", 2, 20);
        sErrorMsgFinale += this.validateEmail(form.email.value, "Email");
        sErrorMsgFinale += this.validateText(form.address_1.value, "Address", false);
        sErrorMsgFinale += this.validateText(form.postcode.value, "Postcode", false, 10);
        sErrorMsgFinale += this.validatePassword(form.pass.value, "Password", false);
        sErrorMsgFinale += this.validateText(form.pass2.value, "Confirm Password", false);
        sErrorMsgFinale += this.validateText(form.city.value, "City", false, 20);
        sErrorMsgFinale += this.validateText(form.phone.value, "Telephone", false, 14);

        if (form.pass.value !== form.pass2.value) {
            sErrorMsgFinale += '- Passwords do not match';
        }

        let stripBrFromMessage = sErrorMsgFinale.replace(/(<([^>]+)>)/ig, '');

        // check if there are any error returned after stripping <br/> tag
        if ($.trim(stripBrFromMessage) !== "") {
            // If any error it will be displayed in the modal
            this.modalReport(sErrorMsgFinale);
            return false;
        } else {

            //get site_client_id and create new client on QDS
            let endopointQDS = "https://beta.qds.quickie-divorce.com/api/client-order";
            let workloadQDS = {
                'domain': 'separation-agreement.co.uk',
                'first_name': form.firstName.value,
                'last_name': form.lastName.value,
                'password': form.pass.value,
                'email': form.email.value,
                'phone': form.phone.value,
                'address_1': form.address_1.value,
                'address_2': form.address_2.value,
                'postcode': form.postcode.value,
                'notes': form.product_title.value,
                'city': form.city.value,
                'package_ki': form.product_ki.value
            }

            // prepare error messages text in case some are triggered
            this.setState({sErrorMsgTitle: "Something went wrong!"});
            this.setState({sErrorMsg: "Message could not be sent for unknown reasons! Please, try later and if the problem  persists contact the customer service."});

            // disable buttons and show invite to "wait" messages
            $(".last_submit").attr("disabled", "disabled");
            $(".spinner-border").removeClass("d-none");
            $(".butt_back").attr("disabled", "disabled");
            $("#payment-gateway-alert").slideDown();

            // API to QDS to create an order and retrieve client_id and order_id
            // to be provided to the next API to the payment gateway
            $.ajax({
                url: endopointQDS,
                method: "POST",
                data: workloadQDS
            }).fail(function () {
                parentThis.modalReport();
                parentThis.restoreButtonsAndAlerts();
            }).done(function (result) {
                if (typeof result.data.client_id !== "undefined" && typeof result.data.order_id !== "undefined") {
                    parentThis.sendPaymentRequest(parentThis, result, form);
                } else {
                    parentThis.modalReport();
                    parentThis.restoreButtonsAndAlerts();
                }
            });
        }
    }

    // send data to create a payment request and be redirected to the payment gateway
    sendPaymentRequest(parentThis, result, form) {
        // prepare data to be sent through API to the payment request UKDSsecure
        let endpointPG = 'https://beta.ukds.net/api/request-payment';
        let ukdsUrlTokenPG = "https://beta.ukds.net/?token=";
        let pre_vat = form.amount.value / 1.2;
        let var_amount = form.amount.value - pre_vat;
        let workloadPG = {
            company_id: 1,
            site_id: 9,
            site_client_id: result.data.client_id,
            site_job_number: result.data.order_id,
            first_name: form.firstName.value,
            last_name: form.lastName.value,
            email: form.email.value,
            tel: form.phone.value,
            address_1: form.address_1.value,
            address_2: form.address_2.value,
            city: form.city.value,
            postcode: form.postcode.value,
            country: 'GB',
            source: 'separation-agreement.co.uk',
            currency: 'GBP',
            amount: form.amount.value,
            pre_vat: pre_vat.toFixed(2),
            vat_amount: var_amount.toFixed(2),
            package: form.product_title.value,
            processed_by: 'WEB',
            assigned_to: 'WEB',
            company_short_name: 'QD',
            securepay_enabled: 1
        }

        let paymentReqeustValidationResponseErrorList = "";

        // API to Payment Gateway
        $.ajax({
            url: endpointPG,
            method: "POST",
            data: workloadPG,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "X-Requested-With": "XMLHttpRequest"
            }
        }).fail(function () {
            parentThis.modalReport();
        }).done(function (result) {
            if (result.message === "Success") {
                window.location.href = ukdsUrlTokenPG + result.data.token;
            } else {
                for (var key in result) {
                    paymentReqeustValidationResponseErrorList += result[key] + "<br/>";
                }
                parentThis.modalReport(paymentReqeustValidationResponseErrorList);
            }
        }).always(function () {
            parentThis.restoreButtonsAndAlerts();
        });
    }

    restoreButtonsAndAlerts() {
        $(".last_submit").removeAttr("disabled");
        $(".butt_back").removeAttr("disabled");
        $(".spinner-border").addClass("d-none");
        $("#payment-gateway-alert").slideUp();
    }

    submitForm() {
        $("[name='frmMember']").submit();
    }

    modalReport(sErrorMessage) {
        let messageReport = '<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
            '<div class="modal-dialog" role="document">' +
            '<div class="modal-content">' +
            '<div class="modal-header">' +
            '<h5 class="modal-title" id="exampleModalLabel">' + this.state.sErrorMsgTitle + '</h5>' +
            '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
            '<span aria-hidden="true">&times;</span>' +
            '</button>' +
            '</div>' +
            '<div class="modal-body">' + (sErrorMessage || this.state.sErrorMsg) + '</div>' +
            '<div class="modal-footer">' +
            '<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        $("body", window.parent.document).append(messageReport);
        $('#exampleModal').modal('show');
    }

    render() {
        const {passwordCharsContent} = this.state;
        return (
            <div onClick={this.closeAllPopover} className="content_wrapper">
                <MetaTags>
                    <title>Order Now</title>
                    <meta id="meta-description" name="description" content="Separation Agreements allow couples to formalise their separation without the hassle of a divorce" />
                    <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Agreement, Quick Agreement, Order Now, Cheap Agreement, Online Agreement, Separation Advice" />
                    <meta id="og-title" property="og:title" content="Order Now" />
                </MetaTags>
                <div id="complete_form" className="p-3">
                    <h1>Order Now</h1>
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <form name="frmMember" method="post" action="">
                                <table width="100%" border="0" cellSpacing="1" cellPadding="4" className="board1">
                                    <tbody className="form_parent">
                                    <tr>
                                        <td colSpan="3" className="p_backcell">
                                            <h5 className="mb-2">
                                                <span className="gray-green">Personalised Service</span> &pound;67
                                            </h5>
                                            <p>
                                                Complete a questionnaire with all the information required to allow us
                                                to draft a Separation Agreement for you. In your package you will also
                                                receive examples of completed agreements to show you how it’s done. FREE
                                                advice will be available via email and over the phone.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">

                                            <p className="order">If you’re ready to begin
                                                your Separation Agreement, please complete the order form below and
                                                select your
                                                desired payment method. Please complete all fields marked with "*"
                                            </p>
                                            <p className="order">After making payment you can begin the simple
                                                questionnaire, or download your documents instantly. This can be
                                                completed at your own pace, everything you enter is saved on our secure
                                                server and you can return at will to finish.
                                            </p>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2" className="p_backcell">
                                            <div className="order_info p-2 my-3">
                                                <p className="mb-2 text-center"><strong>You are ordering:</strong>&nbsp;Option 1&nbsp; - Personalised
                                                    Service
                                                </p><p className="mb-0 text-center">Personalised Service&nbsp;at&nbsp;£67</p>
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td width="115" height="10" className="p_backcell" colSpan="3"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell" height="10"></td>
                                        <td className="p_backcell" colSpan="2"></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="p_backcell"><strong>Please complete the form below:  </strong> </td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">First name *:</td>
                                        <td className="p_backcell">
                                            <input name="firstName" type="text" className="fld1 form-control"
                                                   id="firstName" size="20" maxLength="40"/>
                                            <input type="hidden" name="product_title" value="Personalised Service"/>
                                            <input type="hidden" name="product_ki" value="Sep"/>
                                            <input type="hidden" name="amount" value="67"/>
                                            <input type="hidden" name="qty" value="1"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Last name *:</td>
                                        <td className="p_backcell">
                                            <input name="lastName" type="text" className="fld1 form-control"
                                                   id="lastName" size="20" maxLength="40"/>
                                        </td>

                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Email *:</td>
                                        <td className="p_backcell"><input name="email" type="text"
                                                                          className="fld1 form-control" id="email"
                                                                          size="30" maxLength="80"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <Popover
                                            placement="bottom"
                                            isOpen={passwordCharsContent}
                                            target="passwordCharsContentVerification"
                                            toggle={this.togglePasswordCharsContent}>
                                            <PopoverHeader>A password must have</PopoverHeader>
                                            <PopoverBody>
                                                <ul>
                                                    <li>at least 8 chars</li>
                                                    <li>a capital letter</li>
                                                    <li>a lower case letter</li>
                                                    <li>a number</li>
                                                </ul>
                                            </PopoverBody>
                                        </Popover>
                                        <td className="p_backcell">Password *:</td>
                                        <td className="p_backcell">
                                            <input name="pass" type="password" autoComplete="on"
                                                   className="fld1 form-control" id="pass"
                                                   size="20" maxLength="40"/>
                                        </td>
                                        <td className="p_backcell"><a href="#/" id="passwordCharsContentVerification"
                                                                      className="popoverBox" title="Click">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-question-circle mr-4"
                                                 viewBox="0 0 16 16">
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                                <path
                                                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"></path>
                                            </svg>
                                        </a></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Retype Password *:</td>
                                        <td className="p_backcell"><input name="pass2" type="password" autoComplete="on"
                                                                          className="fld1 form-control"
                                                                          id="pass2" size="20" maxLength="40"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p_backcell">Address 1 *:</td>
                                        <td className="p_backcell">
                                        <textarea name="address_1" cols="35" rows="3" className="fld1 form-control"
                                                  id="address_1"></textarea>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p_backcell">Address 2:</td>
                                        <td className="p_backcell">
                                        <textarea name="address_2" cols="35" rows="3" className="fld1 form-control"
                                                  id="address_2"></textarea>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td valign="top" className="p_backcell">City *:</td>
                                        <td className="p_backcell">
                                            <input name="city" type="text" className="fld1 form-control"
                                                   id="city"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Postcode *:</td>
                                        <td className="p_backcell"><input name="postcode" type="text"
                                                                          className="fld1 form-control"
                                                                          id="postcode" size="10" maxLength="10"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Telephone *:</td>
                                        <td className="p_backcell"><input name="phone" type="text"
                                                                          className="fld1 form-control" id="phone"
                                                                          size="14" maxLength="14"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell">Fax :</td>
                                        <td className="p_backcell"><input name="fax" type="text"
                                                                          className="fld1 form-control" id="fax"
                                                                          size="20" maxLength="30"/>
                                        </td>
                                        <td className="p_backcell"></td>
                                    </tr>
                                    <tr>
                                        <td className="p_backcell"></td>
                                        <td colSpan="2"
                                            className="p_backcell">* required fields
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" align="center">
                                            <div className="row">

                                                <div className="col-md-12 text-lg-left text-md-left text-center mt-2">
                                                    <input type="button" onClick={this.validate.bind(this)}
                                                           name="submit_img" value="Submit" className="but_submit btn_2 last_submit"/>
                                                    <span className="spinner-border spinner-border-sm ml-1 mb-2 d-none"
                                                          role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </span>
                                                </div>
                                            </div>
                                            <div className="row" id="payment-gateway-alert"
                                                 style={{display: this.state.displayNone}}>
                                                <div className="col-12 text-center">
                                                    Please, wait till you are redirected to the payment gateway. Don't
                                                    refresh the page or click any button.
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div valign="top">
                                <div align="center" className="time p_col pt-4">
                                </div>
                                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    <tbody>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <img src="images/securepay.gif" alt="SecurePay - powered by UKDS"
                                                 width="186" height="49" border="0" className="mb-4"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="5"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify">
                                                Our SecurePay through UK Digital Solutions system has been developed by
                                                our team of security experts here at UK Digital Solutions, leaders in
                                                innovation and the latest technology. Our SecurePay payment gateway
                                                operates with extremely dependable and unshakeable military-strength
                                                encryption and the securest servers. Our SecurePay system has never been
                                                compromised. We are fully confident that this system is far safer than
                                                over-the-phone transactions.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="5"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <img src="images/cards.gif" alt="Major Credit/Debit Cards we accept"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">Please make Cheques &amp; POs to:</td>
                                    </tr>
                                    <tr>
                                        <td height="10"></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext"><strong>"QD eForms"</strong></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify mt-4">For discretion, this
                                                is how the billing details will appear on your Credit / Debit Card
                                                Statement.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify">Please read
                                                our <Link className="content_right_link"
                                                          to="disclaimer.html" title="Disclaimer">disclaimer</Link> and <Link
                                                    className="content_right_link"
                                                    to="terms.html" title="terms &amp; conditions" >terms &amp; conditions</Link> before purchasing your Clean Break pack. By clicking 'submit' you
                                                have agreed to our terms, as laid out in the disclaimer and terms &amp;
                                                conditions.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext pb-2">
                                            <Link className="content_right_link p_col" to="terms.html#refund" title="Refunds Policy">Refunds
                                                Policy</Link></td>
                                    </tr>
                                    <tr>
                                        <td align="center" className="boxtext">
                                            <p align="justify">
                                                <strong>NOTE: </strong><font color="#FF0000">After payment
                                                authorisation, you will require
                                                your <strong>email</strong> and <strong>password</strong> to login to our website ... </font></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OrderNow;