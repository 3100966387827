import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";


function WhyChooseUs() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Why a Separation Agreement?</title>
                <meta id="meta-description" name="description" content="Separation-Agreement.co.uk was set up in 2005 by a group of Legal Experts committed to making the processing of downloadable Separation Agreements as quick, simple and affordable as possible" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online" />
                <meta id="og-title" property="og:title" content="Why a Separation Agreement?" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                    <h2>Why a Separation Agreement?</h2>

                    <p>When a couple separates the majority do not want to live together but they do not want to start a
                        divorce straight away either. Some may not want one at all. Instead, these couples seek a remedy
                        that best suits them - a Separation Agreement. </p>

                    <p>No one can predict the future, which makes many people question if a divorce is the right thing.
                        Nobody wants to hastily end their marriage without looking at other options. A Separation
                        Agreement is an ideal solution for couples at the interim period between a breakup and a formal
                        divorce. </p>

                    <p>A divorce is often an extreme measure, and a topic they may not be ready to broach directly after
                        the turmoil of a separation. Rushing into a divorce can often add to tension, cause bitterness
                        and be distressing for children. If you are not ready to discuss the topic of a divorce, a
                        separation (whether trial or permanent), formalised with an agreement can be an ideal stepping
                        stone. </p>

                    <p>A separation can also keep financial and custodial arrangements above board should a divorce be
                        inconvenient for you at the present time. A Separation Agreement gives you and your spouse the
                        time needed to think about the important decisions that need to be made and does not bring about
                        an abrupt end to your marriage. </p>

                    <h3>Why Us</h3>

                    <p>Separation-Agreement.co.uk was set up in 2005 by a group of Legal Experts committed to making the
                        processing of downloadable Separation Agreements as quick, simple and affordable as possible. We
                        are dedicated to providing a quality online service. </p>

                    <p>Every process and document has been approved by our fully qualified legal experts to ensure
                        accuracy and legality in all our documentation. We also provide all our customers with free
                        e-mail and phone support for any queries they have regarding any aspect of their Separation
                        Agreement.</p>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
                <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                    <div className="l_btn">
                        <Link href="order_now.html" className="btn_1" title="Order Now">
                            <span className="b_inner">Order Now</span>
                        </Link>
                        
                    </div>
                    <div className="r_btn">
                        <Link href="services.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </Link>
                    </div>
                </div>
            </div>
            <ContentRight/>
        </div>
    );
}

export default WhyChooseUs;