import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";


function Guarantee() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Guarantee</title>
                <meta id="meta-description" name="description" content="We believe we have developed the quickest, simplest, best quality Separation solution available in the UK" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online" />
                <meta id="og-title" property="og:title" content="Guarantee" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>Our Guarantee</h1>

                <div className="box22 row h-auto">
                    

                    <div className="col-9 d-flex flex-column justify-content-start">
                        <p><b>We want you to be 100% happy or 100% refunded!</b></p>

                        <p>We believe we have developed the quickest, simplest, best quality Separation solution
                            available in the UK.</p>

                        <p>However, we realise that buying on the Internet can make some people feel a bit
                            uneasy.</p>

                        <p>That's why we offer a no-quibble guarantee that completely eliminates any risk, fear or
                            uncertainty you may feel.</p>

                        <p>Therefore, if, within 30 days of purchase, you find another online Separation Agreement
                            product that offers the same quality of service, documentation and support as ours, for
                            less money, we will give you a <b>no-quibble 100% refund GUARANTEED</b>.
                        </p>
                       
                    </div>

                    <div className="col-3 d-flex flex-column justify-content-start align-items-end">
                        <img src="images/badges.png" className="guarantee_image_1" alt="guarantee badges" title="Badges" width={100}/>
                    </div>

                    <div className="bottom d-flex justify-content-between mb-4 mt-lg-4 mb-4 pt-3 col-12">
                            <div className="l_btn">
                                <Link href="order_now.html" className="btn_1" title="Order Now">
                                    <span className="b_inner">Order Now</span>
                                </Link>
                                
                            </div>
                            <div className="r_btn">
                                <Link href="services.html" className="btn_1" title="Read More">
                                    <span className="b_inner">Read More</span>
                                </Link>
                            </div>
                        </div>

                </div>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
                 
            </div>
            <ContentRight/>
        </div>
        );
    }

export default Guarantee;