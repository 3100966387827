import React from "react";
import ContentRight from "../../components/ContentRight";


function Article10() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                <h2>Pros and Cons of a Trial Separation</h2>

                <p>Making a marriage work is not easy. Sometimes, couples end up feeling so frustrated with each
                    other that living together does not seem like an attractive option. However, many couples feel
                    scared of a divorce because of the legal and emotional implications attached to it. </p>

                <p>When couples think that divorce is not the best option but also agree that the marriage has
                    broken down, they try to explore other solutions. One of the solutions for such couples is to
                    opt for a trial separation. </p>

                <p>While many couples are opting for a trial separation these days, it is important to understand
                    that trial separation is not a ‘one size fits all’ kind of a solution. A trial separation should
                    be opted for only if one or both the partners are unsure about a divorce. This form of
                    separation can also work when one or both the partners actually want to give their marriage a
                    second chance. </p>

                <p>A trial separation helps the couple to understand what they truly want; whether they want to
                    ultimately divorce or make their marriage work. In that sense, trial separation can greatly
                    reduce the feeling of guilt that people usually experience when they rush to obtain a
                    divorce. </p>

                <h3>What is a Trial Separation?</h3>

                <p>Trial separation, as the name suggests, is when married couples decide to separate on a trial
                    basis. The couple no longer live together during a trial separation and make separate living
                    arrangements. </p>

                <p>A trial separation usually allows couples to take their time and space and think through about
                    their decision to divorce or what they want out of their marriage etc. Many couples who want to
                    give their marriage a second chance hope that a trial separation may allow for the ‘distance
                    makes a heart grow fonder’ rule to work. </p>

                <p>It is common for couples to meet each other during the trial separation period to discuss and
                    resolve their marital problems. Many couples opt for a trial separation while attending marriage
                    counselling because living apart allows the couple to think about the real issues discussed
                    during counselling sessions and minimise the bitterness that usually persists if the couple
                    stays together. </p>

                <h3>Pros of a Trial Separation</h3>

                <ul>
                    <li><b>Gives Time and space to the couple</b>
                        <br /><br />Amidst heated arguments and bitter conversations, what most couples really need is a
                            break. A trial separation allows couples to stay apart till things cool down and gives
                            the couple the much-needed time and space away from each other to think things over.
                            <br /><br />More often than not, when people are angry they tend to say and do things that
                                they regret later. In a trial separation, bitterness is considerably reduced because
                                people think things over while staying apart.</li>
                    <br />
                        <li><b>Prevents a Rushed Divorce</b><br /><br />
                            It is common for people to rush headlong into a divorce and then regret it later. Many
                            people confess that they are still in love with their partner even long after the
                            divorce is over. This usually happens when couples divorce each other in a fit of anger
                            without resolving their real marital issues. <br /><br />
                            A trial separation allows people to think clearly and assess whether or not divorce is
                            truly what they want. The time away from each other helps people to make decisions in a
                            better manner. Many couples opt for counselling during a trial separation to give their
                            marriage a second chance.</li>
                        <br />
                            <li><b>Helps to Ease Emotions</b><br /><br />
                                When emotions run high, seldom are couples able to resolve any issue. A trial
                                separation allows couples to take time off from each other to cool down their
                                emotions. Subsequently, when partners resume a conflict or an argument, the
                                discussion is likely to be more productive.</li>
                            <br />
                                <li><b>Missing the Other Partner</b><br /><br />
                                    The old age, ‘you don’t know what you got until it’s gone’ holds true in some of
                                    the cases as far as trial separation is concerned. <br /><br />
                                    Many people realise the true worth of their partners when they are not
                                    physically close to them. This helps people understand how empty life would be
                                    if their partners were to leave them some day for good.</li>
                </ul>

                <h3>Cons of a Trial Separation </h3>

                <ul>
                    <li><b>Out of Sight Out of Mind</b>
                        <br /><br />Many people agree that in a time of crisis, the last thing that couples really want
                            is a physical distance because that only builds more barriers in a broken marriage. A
                            trial separation may be used as a means of running away from the issues or marital
                            situations that need to be addressed on an urgent basis.</li>
                    <br />
                        <li><b>False Sense of Security</b><br /><br />
                            Many times, when people start staying apart they think that everything is fine and that
                            the marriage can go back to being normal. However, truth is that a separation period
                            might merely camouflage the issues that continue to exist.<br /><br />
                            In such cases, when couples get back together after the trial separation, the same sets
                            of issues start to resurface.</li>
                        <br/>
                </ul>
            </div>
            <ContentRight />
        </div>
    );
}

export default Article10;