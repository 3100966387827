import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from 'react-meta-tags';


function Home() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Separation agreement - Home</title>
                <meta id="meta-description" name="description" content="Separation Agreement UK - We provide support and documentation to help those going through marital separation " />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, About Separation Agreement, Solicitor Separation Agreement, Quick Separation Agreement, Online Agreement, Separation Advice, Separation Agreement Information, Apply Online" />
                <meta id="og-title" property="og:title" content="Separation agreement - Home" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>Prefer a quick, simple and affordable Separation Agreement?</h1>

                <p>Your <b>Separation Agreement</b> can be initiated online now for <b><u>just £67</u></b>.</p>

                <p>Using our resources we will complete your Separation Agreement and have it on its way to you in
                    a <b>fraction of the time and cost of a High Street Solicitor</b>. Our professional Separation
                    Agreement packages contain everything needed to <b>regulate your Separation</b> in the UK.</p>

                <p><b>Formally set out</b> any complicated arrangements for <b>financial and child visitation
                    matters</b> amongst spouses for the period of time between a split and a divorce or
                    reconciliation. Plus, <b>save at least £1,000</b> with our <b>fixed fee</b> rather than
                    paying <b>£195 per hour</b> at a High Street Solicitor whilst receiving the same high quality of
                    service. </p>

                <p>Last year our <b>experienced team</b> helped thousands of people in the UK to complete a
                    Separation Agreement by providing all the essential documentation, advice and support. Don’t
                    waste hours of your time filling in tedious and confusing forms – get your Separation Agreement
                    finalised promptly, allowing you to get on with your life without worrying about the
                    implications of your separation. </p>

                <p><b>No Complicated Legal Forms!</b> <b>No Solicitors Jargon!</b> All you need are your personal
                    details, a few minutes of your time, and your complete Separation Agreement will be ready to
                    download. We are so confident with the outstanding quality of our legal documents that we cover
                    all of our products with a <b>100% refund GUARANTEE</b>.</p>
                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html" />
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
                    <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                        <div className="l_btn">
                            <a href="order_now.html" title="Order Now" className="btn_1">
                                <span className="b_inner">Order Now</span>
                            </a>
                            
                        </div>
                        <div className="r_btn">
                            <a href="services.html" title="Read More" className="btn_1" >
                                <span className="b_inner">Read More</span>
                            </a>
                        </div>
                    </div>
            </div>
            <ContentRight />
        </div>
    );
}

export default Home;