import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";


function Services() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Services</title>
                <meta id="meta-description" name="description" content="No other online Separation Agreement service provides you with a comparable quality or value-for-money service" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Agreement, Quick Separation, Separation Agreement Services, Fast Separation, Separation Online, Simple Separation ,Separation  Advice, Cheap Agreement " />
                <meta id="og-title" property="og:title" content="Services" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>Services</h1>

                <p>No other online Separation Agreement service provides you with a comparable quality or
                    value-for-money service. We have two different packages available which means you are able to pick
                    the product which caters best for your needs.</p>

                <hr/>

                <h3>Personalised Service £67</h3>

                <p>If you do not feel the need to get a Solicitor involved we will provide you with a <b>completed
                    Personalised</b> Separation Agreement. This includes completing a questionnaire with all the
                    information required to allow us to fill in a <b>Separation Agreement</b>. In your package you
                    will also receive examples of completed agreements to show you how it’s done. <b>FREE</b> advice
                    will be available via email and over the phone. </p>

                <p>The Essential Guide to Separation Agreements will provide you with all the information you need
                    to know when entering into a Separation Agreement. The guide has an RRP of £19.99 but for our
                    customers we will send you this invaluable piece of literature absolutely <b>FREE</b>. As with
                    all our products the Personalised Service is covered by our 100% money back <b>GUARANTEE</b>.
                </p>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html" />
                </div>
                <a href="personal-service.html">
                    <div className="index_btn2"></div>
                </a> */}
                <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                    <div className="l_btn">
                        <Link to="order_now.html" className="btn_1" title="Order Now">
                            <span className="b_inner">Order Now</span>
                        </Link>
                        
                    </div>
                    <div className="r_btn">
                        <Link to="personal-service.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </Link>
                    </div>
                </div>

            </div>
            <ContentRight />
        </div>
    );
}

export default Services;