import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";


function Faq() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>FAQ</title>
                <meta id="meta-description" name="description" content="Frequently asked questions about Separation Agreements, marital separation, the support we offer and other aspects of our service" />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Separation Agreement, Quick Agreement, Frequently Asked Questions, Online Agreement, Separation Agreement Advice, FAQ" />
                <meta id="og-title" property="og:title" content="FAQ" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>Frequently Asked Questions</h1>
                <dl className="row">
                    <dt className="col-sm-3">What is a Separation
                        Agreement?
                    </dt>
                    <dd className="col-sm-9">A Separation Agreement is a contract between a husband and a wife. It
                        resolves all issues
                        relating to child custody, child support, division of property, and debts.
                    </dd>
                    <dt className="col-sm-3">Can I draft a
                        Separation Agreement without a Solicitor?
                    </dt>
                    <dd className="col-sm-9">Yes. The agreement must be in writing and signed by husband and wife.</dd>
                    <dt className="col-sm-3">What happens when we're not able to agree?</dt>
                    <dd className="col-sm-9">When parties are not able to reach an agreement on some or all of these
                        issues, it may become necessary for them to go to court and have a judge make these decisions.
                    </dd>
                    <dt className="col-sm-3">How long will it take for my agreement to be effective?</dt>
                    <dd className="col-sm-9">The agreement will be effective from the moment you sign it.</dd>
                    <dt className="col-sm-3">Why is your service so cheap?</dt>
                    <dd className="col-sm-9">We are an expert online legal service. Therefore, we have considerably
                        fewer overheads than a high street Solicitor. This keeps our costs down to a minimum, allowing
                        us to pass on our savings to our customers.
                    </dd>
                    <dt className="col-sm-3">Are my bank details safe?</dt>
                    <dd className="col-sm-9">All our payment transactions for Separation Agreement UK are processed by
                        Sage Pay. Sage Pay provides very high levels of internet and physical security to ensure
                        sensitive credit/debit card information is never compromised.
                    </dd>
                </dl>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}

                <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                    <div className="l_btn">
                        <Link href="order_now.html" className="btn_1" title="Order Now">
                            <span className="b_inner">Order Now</span>
                        </Link>
                        
                    </div>
                    <div className="r_btn">
                        <Link href="services.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </Link>
                    </div>
                </div>

            </div>
            <ContentRight/>
        </div>
    );
}

export default Faq;