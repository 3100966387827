import React from "react";
import ContentRight from "../../components/ContentRight";


function Article2() {
    return (
        <div className="content_wrapper">
            <div className="main_left col-md-9 col-sm-12">
                    <h2>Changes Likely in the Family Law to Reinstate the Importance of Marriage</h2>

                    <p>The Centre for Social Justice has released an interim report that questions areas such as taboos
                        on divorce, fathers, marriage, and family law. The former Conservative leader Iain Duncan Smith
                        has proposed certain changes in the interim report that are likely to have an impact on how
                        people view marriage and cohabitation. </p>

                    <p>According to Mr. Duncan Smith, the report was “timely” considering Baby P’s death and other
                        scandals of the recent times which highlight the “chaotic nature” of family life in
                        Britain. </p>

                    <p>The report proposes changes in the family law to prevent children from losing contact from their
                        fathers and grandparents after a divorce. The report links rising rate of family breakdown to a
                        substantial increase in cohabitation rates. It highlights the fact that married couples are far
                        less likely to break-up than people who are living together without getting married. </p>

                    <p>The review committee questions whether British law should remove the ‘disincentives’ associated
                        with marriage by altering the divorce laws and increasing the importance of pre-nuptial
                        agreements. The advocates of the report suggest using the law to discourage cohabitation
                        arrangements and adopt measures to make marriage an attractive option and divorce more
                        difficult. </p>

                    <p>Mr. Smith said that “25% of children in this country live in single parent families and this
                        trend is set to accelerate. These children are three to six times more likely to experience
                        abuse.” </p>

                    <p>The aim of the review is to find laws that can support and encourage pro-social norms which are
                        facing a danger of being washed away. The review also aims to recognise the importance of
                        long-term steady relationships and pro-social institutions for connection, identity, and
                        belonging. </p>

                    <p>The report suggests that certain measures need to be adopted such as establishment of Family
                        Relationship Centres, which recommend early intervention strategies to help the separating
                        parents. </p>

                    <p>The final report will be released in spring 2009. The recommendations will most likely be adopted
                        by the Conservative Party.</p>
                </div>
            <ContentRight />
        </div>
    );
}

export default Article2;